@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
    font-family:'hk-bold-italic';
    src: url(/fonts/HKGrotesk-BoldItalic.ttf);
}


* {
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: calc(1vw + 4rem);
    letter-spacing: 1px;
    font-family: 'Bebas Neue', cursive;
}
h2 {
    font-size: calc(1vw + 3rem);
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 1px;
  }

h3 {
  font-size: calc(1vw + 2rem);
  font-family: 'Bebas Neue', cursive;

}

h4 {
    font-size: calc(1vw + 1rem);
    font-family: 'Bebas Neue', cursive;
}

p {
    font-size: calc(1vw + 0.2rem);
}

@media (max-width: 1399.98px) {
    p {
        font-size: calc(1vw + 0.4rem);
    }
}

@media (max-width: 1199.98px) {
    p {
        font-size: calc(1vw + 0.6rem);
    }
}

@media (max-width: 991.98px) {
    p {
        font-size: calc(1vw + 0.8rem);
    }
}

@media (max-width: 767.98px) {
    p {
        font-size: calc(1vw + 0.9rem);
    }
}

@media (max-width: 575.98px) {
    p {
        font-size: calc(1vw + 1rem);

        @include media (md) {

        }
    }
    h1,
    h2,
    h3,
    h4,
    p {
        text-align: center;
    }
}
