.banner-hero {

    .background-config {
        height: 70vh;
    }
   
    .title-wrapper {
        top: 30%;
        left: 10%;
        .title {
            text-align: start;
            font-size: 5rem;
            font-family:'hk-bold-italic';
            color: color(white-500);
        }

        .subtitle-wrapper {
            display: flex;
            flex-direction: column;
            color: color(green-300);
            font-size: 4rem;
            border-left: 2px solid #D3A130;
            padding: 0.3rem 1rem;
        }
    } 
}

// X-Small devices (portrait phones, less than 576px)
@include media(sm) {
    // ...
}

// Small devices (landscape phones, less than 768px)
@include media(md) {
  
    // ...
}

// Medium devices (tablets, less than 992px)
@include media(lg) {
    // ...
}

// Large devices (desktops, less than 1200px)
@include media(xl) {
    // ...
}

// X-Large devices (large desktops, less than 1400px)
@include media(xxl) {
    // ...
}