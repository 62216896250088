.card {
  box-shadow: 0 4px 4px rgba(#000, 25%);
  border-radius: 16px;
  padding: 16px;

  .card-content {
    padding: 24px;
  }

  .card-actions {
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid rgba(#c5c5c5, 80%);
    margin-top: 24px;
    text-align: right;
  }
}

.card-cotacao-destaque {
  box-shadow: 0 4px 4px rgba(#000, 25%);
  border-radius: 16px;
  padding: 16px;
  margin: 0 2rem;

  .cotacao-title {
    font-weight: bold;
  }

  span.cotacao-text {
    font-weight: bold;
  }
}

.card-valores {
  padding: 16px;
  transition: .2s ease-in-out;

  &:hover {
    background-color: color(green-300);
    color: color(green-500);
  }
}

.card-valores {
  padding: 16px;
  transition: .2s ease-in-out;

  &:hover {
    background-color: color(green-300);
    color: color(green-500);
  }
}

.card-lojas {
  transition: .2s ease-in-out;
  box-shadow: 0 4px 4px rgba(#000, 25%);
  background-color: color(white-500);

  .background-config {
    padding: 12rem 0;
  }

  .card-content {
    padding: 15px 30px;
    height: 400px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    .card-title {
      font-family: 'Bebas Neue', cursive;
      font-size: 30px;
    }



    span {
      font-size: 14px;
      font-weight: 600;
      text-align: start;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    span.title-observações {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .fa-solid {
      font-size: 25px;
    }
  }
}

.color-link{
  color: #212529 !important;
}

.card-relatorio {
  margin: 8px;
  box-shadow: 0 4px 4px rgba(#000, 25%);
  cursor: pointer;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  padding: 0 !important;
  transition: .2s ease-in-out !important;

  .card-img-bg{
    background-image: linear-gradient(138deg, #153b34b8, #6bb180d6, #153b34a1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 180px;
    background-size: cover;
    padding: 2.4em;
    transition: .2s ease-in-out !important;
  }

  .card-content{
    padding: 1em 0.8em;
    line-height: 24px;
  }
  
  .card-title {
    font-family: 'Bebas Neue', cursive;
    font-size: 32px;
  }

  &:hover{
    box-shadow: 0 4px 4px rgba(#6bb180d6, 25%);
    .card-img-bg{
      filter: brightness(112%);
    }

    .card-link{
      color: rgb(2, 70, 148);
    }
  }
  
  @media screen and (max-width: 720px) {
    margin: 8px auto;
    max-width: 88%;
  }
}




.card-servicos {
  transition: .2s ease-in-out;
  box-shadow: 0 4px 4px rgba(#000, 25%);
  background-color: color(white-500);
  /* padding: 14rem; */

  height: 300px;
  margin-bottom: 3rem;
  position: relative;

  .button-link {
    bottom: -15px;
    z-index: 999;
  }

  .card-hover {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
    position: relative;
    opacity: 0 ;
    background-image: linear-gradient(138deg, #153b34b8, #6bb180d6, #153b34a1);


    p {
      font-family: 'Bebas Neue', cursive;
      color: color(white-500);
      font-size: 30px;
    }
  }

  &:hover {
    .card-hover {
      opacity: 1;
    }
  }
}
