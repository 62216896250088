/**
 * General reset
 */
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;

  /**
   * Custom scrollbar
   */
  &::-webkit-scrollbar {
    width: 1.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: color(green-300);
    outline: none;
    border-radius: 0rem;
  }

  /**
   * Custom selection color
   */
  ::selection {
    background-color: color(green-300);
    color: color(green-500);
  }
}

html, body {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-size: 1.4rem;

  main {
    flex: 1;
    overflow: hidden;
  }
}

/**
 * Bootstrap reset
 */

// Tooltips
.tooltip {
  font-size: 16px;

  .tooltip-arrow {
    &::before {
      border-right-color: rgba(#3a3a3a, 90%);
      border-radius: 5px;
    }
  }

  .tooltip-inner {
    padding: 8px 16px;
    background-color: rgba(#3a3a3a, 90%);
    border-radius: 4px;
  }
}

img {
    -webkit-user-drag: none;

    &.px {
        image-rendering: pixelated;
    }

    &.aa {
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: crisp-edges;
        image-rendering: optimize-contrast;
        -ms-interpolation-mode: nearest-neighbor;
    }
}

a {
    text-decoration: none;
    color: color(primary);
}

ul {
    list-style: none;
}

/* Animations */
@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(15px);
    }

    100% {
        transform: translateY(0);
    }
}

/* Helpers */
.up {
    text-transform: uppercase;
}

.greyscale {
    filter: grayscale(100%);
}

[disabled]:hover {
    cursor: not-allowed;
}

.padding-top {
  padding-top: 10rem;
}

.padding-5 {
  padding: 5rem 0;
}

.background-config {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.erro-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  h2 {
      font-size: 10rem;
  }
}

/* colors */
.text-white-300 {
  color: #fff;
}

.text-green-300 {
  color: #B8DAC2;
}

.text-green-600 {
  color: #153B34;
}

.text-black-500 {
  color: #151515;
}

.text-red {
  color: #E53E3E;
}

.bg-grey-500 {
  background-color: #CEC9C1;
}
.bg-green-500 {
  background-color: #153B34;
}

.bg-green-300 {
  background-color: #B8DAC2;
}

.bg-white-500 {
  background-color: #fff ;
}



/* Buttons */
button {
    all: unset;
}

// Bootstrap resets and fix
.btn {
    padding: 12px 16px;
    font-size: 16px;
}

.form-control:focus {
    box-shadow: none;
}
