.content-simulador {
	padding: 20px 0;
	padding: 1rem;
	border-radius: 10px;
	background-color: #fff;

	.selectbox-one {
		background: #fff;
		width: 100%;
		box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		transition: .2s ease all;
		padding: 20px;
		position: relative;
		z-index: 200;
		border: 2px solid transparent;
		height: 11rem;
		margin: 1rem 0;

		label {
			font-weight: 600;
			font-size: 18px;
		}

		input {
			font-size: 20px;
			border: none;
		}
	}

	.selectbox-two {
		background: #fff;
		width: 100%;
		box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		transition: .2s ease all;
		padding: 20px;
		position: relative;
		z-index: 200;
		border: 2px solid transparent;
		height: 11rem;
		margin: 1rem 0;

		img {
			width: 60px;
			height: 60px;
			margin-right: 30px;
		}

		label {
			font-weight: 600;
			font-size: 18px;
		}

		input {
			font-size: 20px;
			border: none;
		}
	}

	.selectbox {
		margin: auto;
		position: relative;
		z-index: 500;

		.select {
			background: #fff;
			width: 100%;
			box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
			border-radius: 5px;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: .2s ease all;
			padding: 20px;
			position: relative;
			z-index: 200;
			border: 2px solid transparent;
			height: 11rem;
			margin: 1rem 0;

			&.active,
			&:hover {
				box-shadow: 0px 5px 10px rgba(0, 0, 0, .16);
				border: 2px solid #B8DAC2;
			}

			&.active:before {
				content: "";
				display: block;
				height: 0;
				width: 0;
				border-top: 10px solid #153B34;
				border-right: 10px solid transparent;
				border-bottom: 10px solid transparent;
				border-left: 10px solid transparent;
				position: absolute;
				bottom: -22px;
				left: calc(50% - 15px);
			}

			i {
				font-size: 30px;
				margin-left: 30px;
				color: color(green-500);
			}

			img {
				width: 60px;
				height: 60px;
				margin-right: 30px;
			}
		}
	}
}

.contenido-select {
	display: flex;
	align-items: center;
}

.titulo {
	color: color(green-500);
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
	font-size: 18px;
	margin: 0;
}

.descripcion {
	font-size: 18px;
	color: #434343;
	margin-bottom: 0;
}

.opciones {
	background: #fff;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0,0,0,.16);
	max-height: 400px;
	overflow: auto;
	z-index: 99999;
	width: 100%;
	display: none;
	position: absolute;
}

.opciones.active {
	display: block;
	animation: fadeIn .3s forwards;
}

@keyframes fadeIn {
	from {
		transform: translateY(-100px) scale(.5);
	}
	to {
		transform: translateY(0) scale(1);
	}
}

.contenido-opcion {
	width: 100%;
	display: flex;
	align-items: center;
	transition: .2s ease all;
}

.opciones .contenido-opcion {
	padding: 20px;
}

.contenido-opcion img {
	width: 60px;
	height: 60px;
	margin-right: 30px;
}

.opciones .contenido-opcion:hover {
	background: #153B34;
}

.opciones .contenido-opcion:hover .titulo,
.opciones .contenido-opcion:hover .descripcion {
	color: #fff;
}

.form-control:disabled {
    background-color: #fff;
    cursor: default;
}

@media screen and (max-width: 800px){
	.selectbox {
		width: 100%;
	}
}
