.modal-alerta {

    .modal-dialog {
      max-width: 1200px;
      .modal-content {
        font-size: 1.6rem;
        border-radius: 1rem;



        .modal-header {
          display: flex;
          justify-content: space-between;
          border-bottom: none;
          padding: 1rem 4rem;
        }

        .modal-body {
          padding: 2rem 4rem;

          .alerta-wrapper {
            padding: 1rem;

            .table {
                background-color: white;
                .img-flag {
                    width: 50px;
                }

                .tr-padding {
                    padding: 2rem 0;
                }

                span {
                  font-weight: 600;
                }
            }

            .form-label {
                color: color(green-500);
                font-weight: 600;
            }

            .form-control {
                height: 42px;
                font-size: 1.6rem;
            }

            .text-muted {
                display: none;
            }
          }
        }
      }
    }
  }


  .modal-content{
    width: 50%;
    margin-left: 25%;
}


@media screen and (max-width:747px) {
  .modal-content{
      width: 92vw;
      margin:auto;
  }
}
