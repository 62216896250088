// Mobile-first
.home-hero {
    .swiper {
            height: 142px;
        .swiper-slide {
            background-size: cover;
            background-repeat: no-repeat;

            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
        
            
            .swiper-img {
                object-fit: cover;
                width: 100%;
            }

            .swiper-title-wrapper {
                .title {
                    font-size: 4rem;
                    font-family:'hk-bold-italic';
                    color: color(white-500);
                }

                .subtitle-wrapper {
                    display: flex;
                    flex-direction: column;
                    color: color(green-300);
                    font-size: 4rem;
                    border-left: 2px solid #D3A130;
                    padding: 0.3rem 1rem;
                }
            }            
        }

        .swiper-button-prev, .swiper-button-next {
            padding: 5rem 2.5rem;
            background-color: color(green-500);
           
            &::after {
                color: color(white-500);
                font-size: 25px;  
            }
        }

        .swiper-button-prev {
            left: 0;
            border-radius: 0 5px 5px 0;
        }

        .swiper-button-next {
            right: 0;
            border-radius: 5px 0 0 5px;
        }
    }
}

.simulador-wrapper {
    background-color: color(green-500);
}

@media (min-width: 575.98px) {

    .home-hero {
        .swiper,  .swiper-img  {
            height: 200px;
           
        }
    }
}

@media (min-width: 767.98px) {

    .home-hero {
        .swiper,  .swiper-img  {
            height: 368px;
        }
    }
}

@media (min-width: 991.98px) {

}

@media (min-width: 1199.98px) {

    .home-hero {
        .swiper,  .swiper-img  {
            height: 554px;
        }
    }
}

@media (min-width: 1399.98px) {
    .home-hero {
        .swiper,  .swiper-img  {
            height: 550px;
        }
    }
} 


@media (min-width: 1599.98) {
    .home-hero {
        .swiper,  .swiper-img  {
            height: 700px;
        }
    }
}

@media (min-width: 1920.98) {
    .home-hero {
        .swiper,  .swiper-img  {
            height: 745px;
        }
    }
}

@media (min-width: 2100.98px) {
    .home-hero {
        .swiper,  .swiper-img  {
            height: 845px;
        }
    }
}