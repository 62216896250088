.servicos-wrapper {
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-button-prev, .swiper-button-next {
        padding: 3rem 2.5rem;
        background-color: color(green-500);
        &::after {
            color: color(white-500);
            font-size: 25px;  
        }
    }

    .swiper-button-prev {
        left: 0;
        border-radius: 0 5px 5px 0;
    }

    .swiper-button-next {
        right: 0;
        border-radius: 5px 0 0 5px;
    }
}