.modal-theme {
  .modal-dialog {
    max-width: 1200px;
    .modal-content {
      font-size: 1.6rem;
      border-radius: 1rem;

      .modal-header {
        display: flex;
        justify-content: end;
      }

      .modal-img-header {
        padding: 1rem;
        img {
          object-fit: cover;
          height: 300px;
          width: 100%;
            @media (max-width: 575.98px){
              height: 160px;
            }
          }
        }
        

      .modal-body {
        padding: 2rem 4rem;
        color: color(green-500);

        .modal-title {
          line-height: 53px;
        }

        .title {
          font-weight: 600;
        }

        ul {
          li {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}


@include media (lg) {

  .modal-theme {
    .modal-dialog {
      margin: 1rem;
    }
  }
}

