@charset "UTF-8";
/**
 * General reset
 */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  /**
   * Custom scrollbar
   */
  /**
   * Custom selection color
   */
}
html::-webkit-scrollbar {
  width: 1.6rem;
}
html::-webkit-scrollbar-track {
  background-color: #fff;
}
html::-webkit-scrollbar-thumb {
  background-color: #B8DAC2;
  outline: none;
  border-radius: 0rem;
}
html ::selection {
  background-color: #B8DAC2;
  color: #006400;
}

html, body {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-size: 1.4rem;
}
body main {
  flex: 1;
  overflow: hidden;
}

/**
 * Bootstrap reset
 */
.tooltip {
  font-size: 16px;
}
.tooltip .tooltip-arrow::before {
  border-right-color: rgba(58, 58, 58, 0.9);
  border-radius: 5px;
}
.tooltip .tooltip-inner {
  padding: 8px 16px;
  background-color: rgba(58, 58, 58, 0.9);
  border-radius: 4px;
}

img {
  -webkit-user-drag: none;
}
img.px {
  image-rendering: pixelated;
}
img.aa {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/* Animations */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}
/* Helpers */
.up {
  text-transform: uppercase;
}

.greyscale {
  filter: grayscale(100%);
}

[disabled]:hover {
  cursor: not-allowed;
}

.padding-top {
  padding-top: 10rem;
}

.padding-5 {
  padding: 5rem 0;
}

.background-config {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.erro-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.erro-wrapper h2 {
  font-size: 10rem;
}

/* colors */
.text-white-300 {
  color: #fff;
}

.text-green-300 {
  color: #B8DAC2;
}

.text-green-600 {
  color: #153B34;
}

.text-black-500 {
  color: #151515;
}

.text-red {
  color: #E53E3E;
}

.bg-grey-500 {
  background-color: #CEC9C1;
}

.bg-green-500 {
  background-color: #153B34;
}

.bg-green-300 {
  background-color: #B8DAC2;
}

.bg-white-500 {
  background-color: #fff;
}

/* Buttons */
button {
  all: unset;
}

.btn {
  padding: 12px 16px;
  font-size: 16px;
}

.form-control:focus {
  box-shadow: none;
}

@font-face {
  font-family: "hk-bold-italic";
  src: url(/fonts/HKGrotesk-BoldItalic.ttf);
}
* {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: calc(1vw + 4rem);
  letter-spacing: 1px;
  font-family: "Bebas Neue", cursive;
}

h2 {
  font-size: calc(1vw + 3rem);
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
}

h3 {
  font-size: calc(1vw + 2rem);
  font-family: "Bebas Neue", cursive;
}

h4 {
  font-size: calc(1vw + 1rem);
  font-family: "Bebas Neue", cursive;
}

p {
  font-size: calc(1vw + 0.2rem);
}

@media (max-width: 1399.98px) {
  p {
    font-size: calc(1vw + 0.4rem);
  }
}
@media (max-width: 1199.98px) {
  p {
    font-size: calc(1vw + 0.6rem);
  }
}
@media (max-width: 991.98px) {
  p {
    font-size: calc(1vw + 0.8rem);
  }
}
@media (max-width: 767.98px) {
  p {
    font-size: calc(1vw + 0.9rem);
  }
}
@media (max-width: 575.98px) {
  p {
    font-size: calc(1vw + 1rem);
  }

  h1,
h2,
h3,
h4,
p {
    text-align: center;
  }
}
.text-green-300 {
  color: #B8DAC2 !important;
}

.bg-green-300 {
  background-color: #B8DAC2 !important;
}

.text-green-500 {
  color: #006400 !important;
}

.bg-green-500 {
  background-color: #006400 !important;
}

.text-red-500 {
  color: #D3A130 !important;
}

.bg-red-500 {
  background-color: #D3A130 !important;
}

.text-white-500 {
  color: #FFFFFF !important;
}

.bg-white-500 {
  background-color: #FFFFFF !important;
}

.text-grey-500 {
  color: #CEC9C1 !important;
}

.bg-grey-500 {
  background-color: #CEC9C1 !important;
}

.text-grey-600 {
  color: #333333 !important;
}

.bg-grey-600 {
  background-color: #333333 !important;
}

.text-black-500 {
  color: #151515 !important;
}

.bg-black-500 {
  background-color: #151515 !important;
}

.btn-comprar, .btn-receber-alerta, .btn-flat {
  display: inline-block;
  border-radius: 4px;
  padding: 12px 16px;
  border: none;
  transition: 0.2s ease-in-out;
  font-size: 18px;
}

/**
 * Solid button
 */
.btn-theme {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
}
.btn-theme:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #B8DAC2;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}
.btn-theme span {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #006400;
}
.btn-theme svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #006400;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}
.btn-theme:hover:before {
  width: 100%;
  background: #B8DAC2;
}
.btn-theme:hover svg {
  transform: translateX(0);
}
.btn-theme:active {
  transform: scale(0.95);
}

/**
 * Flat button
 */
.btn-flat {
  background-color: transparent;
  color: #202020;
  box-shadow: none;
}
.btn-flat:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #202020;
}
.btn-flat:active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #202020;
}
.btn-flat:focus-visible {
  background-color: rgba(0, 0, 0, 0.2);
}

.btn-receber-alerta {
  background-color: #B8DAC2;
  font-family: "Bebas Neue", cursive;
  color: #006400;
  box-shadow: none;
  display: flex;
  font-size: 18px;
}
@media (max-width: 575.98px) {
  .btn-receber-alerta {
    padding: 10px;
  }
}
.btn-receber-alerta img {
  width: 20px;
}
@media (max-width: 575.98px) {
  .btn-receber-alerta img {
    width: 10px;
  }
}
.btn-receber-alerta:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #202020;
}
.btn-receber-alerta:active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #202020;
}
.btn-receber-alerta:focus-visible {
  background-color: rgba(0, 0, 0, 0.2);
}

.btn-comprar {
  background-color: #D3A130;
  font-family: "Bebas Neue", cursive;
  color: #FFFFFF;
  box-shadow: none;
  font-size: 18px;
}
.btn-comprar:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #202020;
}
.btn-comprar:active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #202020;
}
.btn-comprar:focus-visible {
  background-color: rgba(0, 0, 0, 0.2);
}

.btn-newsletter {
  background-color: #D3A130;
  font-family: "Bebas Neue", cursive;
  color: #FFFFFF;
  box-shadow: none;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 16px;
  border: none;
  transition: 0.2s ease-in-out;
  font-size: 18px;
}
.btn-newsletter:hover {
  background-color: #C99318;
  color: #FFFFFF;
}
.btn-newsletter:active {
  background-color: #C99318;
  color: #FFFFFF;
}
.btn-newsletter:focus-visible {
  background-color: #C99318;
}

.btn-oblique {
  background-color: #D3A130;
  font-family: "Bebas Neue", cursive;
  color: #FFFFFF;
  box-shadow: none;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 20px;
  border: none;
  transition: 0.2s ease-in-out;
  font-size: 20px;
  -webkit-transform: skewX(-14deg);
  -ms-transform: skewX(-14deg);
  transform: skewX(-14deg);
  margin-bottom: 15px;
}
.btn-oblique:hover {
  background-color: #C99318;
  color: #FFFFFF;
}
.btn-oblique:active {
  background-color: #C99318;
  color: #FFFFFF;
}
.btn-oblique:focus-visible {
  background-color: #C99318;
}

.card {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px;
}
.card .card-content {
  padding: 24px;
}
.card .card-actions {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid rgba(197, 197, 197, 0.8);
  margin-top: 24px;
  text-align: right;
}

.card-cotacao-destaque {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 16px;
  margin: 0 2rem;
}
.card-cotacao-destaque .cotacao-title {
  font-weight: bold;
}
.card-cotacao-destaque span.cotacao-text {
  font-weight: bold;
}

.card-valores {
  padding: 16px;
  transition: 0.2s ease-in-out;
}
.card-valores:hover {
  background-color: #B8DAC2;
  color: #006400;
}

.card-valores {
  padding: 16px;
  transition: 0.2s ease-in-out;
}
.card-valores:hover {
  background-color: #B8DAC2;
  color: #006400;
}

.card-lojas {
  transition: 0.2s ease-in-out;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
}
.card-lojas .background-config {
  padding: 12rem 0;
}
.card-lojas .card-content {
  padding: 15px 30px;
  height: 400px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.card-lojas .card-content .card-title {
  font-family: "Bebas Neue", cursive;
  font-size: 30px;
}
.card-lojas .card-content span {
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-lojas .card-content span.title-observações {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-lojas .card-content .fa-solid {
  font-size: 25px;
}

.color-link {
  color: #212529 !important;
}

.card-relatorio {
  margin: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  padding: 0 !important;
  transition: 0.2s ease-in-out !important;
}
.card-relatorio .card-img-bg {
  background-image: linear-gradient(138deg, #153b34b8, #6bb180d6, #153b34a1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  background-size: cover;
  padding: 2.4em;
  transition: 0.2s ease-in-out !important;
}
.card-relatorio .card-content {
  padding: 1em 0.8em;
  line-height: 24px;
}
.card-relatorio .card-title {
  font-family: "Bebas Neue", cursive;
  font-size: 32px;
}
.card-relatorio:hover {
  box-shadow: 0 4px 4px rgba(107, 177, 128, 0.25);
}
.card-relatorio:hover .card-img-bg {
  filter: brightness(112%);
}
.card-relatorio:hover .card-link {
  color: #024694;
}
@media screen and (max-width: 720px) {
  .card-relatorio {
    margin: 8px auto;
    max-width: 88%;
  }
}

.card-servicos {
  transition: 0.2s ease-in-out;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #FFFFFF;
  /* padding: 14rem; */
  height: 300px;
  margin-bottom: 3rem;
  position: relative;
}
.card-servicos .button-link {
  bottom: -15px;
  z-index: 999;
}
.card-servicos .card-hover {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
  opacity: 0;
  background-image: linear-gradient(138deg, #153b34b8, #6bb180d6, #153b34a1);
}
.card-servicos .card-hover p {
  font-family: "Bebas Neue", cursive;
  color: #FFFFFF;
  font-size: 30px;
}
.card-servicos:hover .card-hover {
  opacity: 1;
}

.site-footer-informations {
  background-color: #151515;
  padding: 3rem 0;
}
.site-footer-informations p {
  font-size: 16px;
  font-weight: 600;
}
.site-footer-informations .nav-footer {
  border-top: 2px solid white;
}
.site-footer-informations .nav-footer .nav-item .nav-link {
  color: #FFFFFF;
  font-weight: 600;
}
.site-footer-informations .nav-footer .nav-item .nav-link:hover {
  color: #B8DAC2;
}
.site-footer-informations .col-center {
  border-right: 2px solid white;
  border-left: 2px solid white;
}
.site-footer-informations i {
  font-size: 40px;
  color: #FFFFFF;
  transition: all 0.3s ease-in-out;
}
.site-footer-informations i:hover {
  color: #B8DAC2;
}

.site-footer-developed {
  background-color: #333333;
}
.site-footer-developed .wrapper {
  display: flex;
  padding: 1rem 0;
}
.site-footer-developed .wrapper span, .site-footer-developed .wrapper a {
  color: #FFFFFF;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .site-footer-informations .nav-footer {
    border-top: none;
  }
  .site-footer-informations .col-center {
    border-right: none;
    border-left: none;
  }
}
.mensagem-alerta {
  z-index: 2;
  top: 112px;
  right: 16px;
  position: absolute;
  max-width: 400px;
}
@media (max-width: 568px) {
  .mensagem-alerta {
    left: 8px;
    right: 8px;
  }
}

.modal-theme .modal-dialog {
  max-width: 1200px;
}
.modal-theme .modal-dialog .modal-content {
  font-size: 1.6rem;
  border-radius: 1rem;
}
.modal-theme .modal-dialog .modal-content .modal-header {
  display: flex;
  justify-content: end;
}
.modal-theme .modal-dialog .modal-content .modal-img-header {
  padding: 1rem;
}
.modal-theme .modal-dialog .modal-content .modal-img-header img {
  object-fit: cover;
  height: 300px;
  width: 100%;
}
@media (max-width: 575.98px) {
  .modal-theme .modal-dialog .modal-content .modal-img-header img {
    height: 160px;
  }
}
.modal-theme .modal-dialog .modal-content .modal-body {
  padding: 2rem 4rem;
  color: #006400;
}
.modal-theme .modal-dialog .modal-content .modal-body .modal-title {
  line-height: 53px;
}
.modal-theme .modal-dialog .modal-content .modal-body .title {
  font-weight: 600;
}
.modal-theme .modal-dialog .modal-content .modal-body ul li {
  margin-bottom: 1rem;
}

@media screen and (max-width: 992px) {
  .modal-theme .modal-dialog {
    margin: 1rem;
  }
}
.modal-alerta .modal-dialog {
  max-width: 1200px;
}
.modal-alerta .modal-dialog .modal-content {
  font-size: 1.6rem;
  border-radius: 1rem;
}
.modal-alerta .modal-dialog .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: none;
  padding: 1rem 4rem;
}
.modal-alerta .modal-dialog .modal-content .modal-body {
  padding: 2rem 4rem;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper {
  padding: 1rem;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .table {
  background-color: white;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .table .img-flag {
  width: 50px;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .table .tr-padding {
  padding: 2rem 0;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .table span {
  font-weight: 600;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .form-label {
  color: #006400;
  font-weight: 600;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .form-control {
  height: 42px;
  font-size: 1.6rem;
}
.modal-alerta .modal-dialog .modal-content .modal-body .alerta-wrapper .text-muted {
  display: none;
}

.modal-content {
  width: 50%;
  margin-left: 25%;
}

@media screen and (max-width: 747px) {
  .modal-content {
    width: 92vw;
    margin: auto;
  }
}
.navbar {
  background-color: #006400;
  padding: 2.2rem 0.5rem;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.navbar .navbar-toggler i.fas {
  color: #B8DAC2;
  font-size: 3rem;
}
.navbar .navbar-collapse .navbar-nav .nav-item {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
}
.navbar .navbar-collapse .navbar-nav .nav-item:not(:last-child) {
  margin-right: 1.5rem;
}
.navbar .navbar-collapse .navbar-nav .nav-item > div i {
  font-size: 3rem;
  color: #B8DAC2;
  transition: all 0.3s ease-in-out;
}
.navbar .navbar-collapse .navbar-nav .nav-item > div i:hover {
  color: #D3A130;
}
.navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #FFFFFF;
  font-size: 2rem;
}
.navbar .navbar-collapse .navbar-nav .nav-item:hover:not(.active) .nav-link {
  color: #B8DAC2;
}
.navbar .navbar-collapse .navbar-nav .nav-item.active {
  font-weight: 600;
  border-bottom: 2px solid #D3A130;
}

.content-simulador {
  padding: 20px 0;
  padding: 1rem;
  border-radius: 10px;
  background-color: #fff;
}
.content-simulador .selectbox-one {
  background: #fff;
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: 0.2s ease all;
  padding: 20px;
  position: relative;
  z-index: 200;
  border: 2px solid transparent;
  height: 11rem;
  margin: 1rem 0;
}
.content-simulador .selectbox-one label {
  font-weight: 600;
  font-size: 18px;
}
.content-simulador .selectbox-one input {
  font-size: 20px;
  border: none;
}
.content-simulador .selectbox-two {
  background: #fff;
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  transition: 0.2s ease all;
  padding: 20px;
  position: relative;
  z-index: 200;
  border: 2px solid transparent;
  height: 11rem;
  margin: 1rem 0;
}
.content-simulador .selectbox-two img {
  width: 60px;
  height: 60px;
  margin-right: 30px;
}
.content-simulador .selectbox-two label {
  font-weight: 600;
  font-size: 18px;
}
.content-simulador .selectbox-two input {
  font-size: 20px;
  border: none;
}
.content-simulador .selectbox {
  margin: auto;
  position: relative;
  z-index: 500;
}
.content-simulador .selectbox .select {
  background: #fff;
  width: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 20px;
  position: relative;
  z-index: 200;
  border: 2px solid transparent;
  height: 11rem;
  margin: 1rem 0;
}
.content-simulador .selectbox .select.active, .content-simulador .selectbox .select:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  border: 2px solid #B8DAC2;
}
.content-simulador .selectbox .select.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-top: 10px solid #153B34;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: -22px;
  left: calc(50% - 15px);
}
.content-simulador .selectbox .select i {
  font-size: 30px;
  margin-left: 30px;
  color: #006400;
}
.content-simulador .selectbox .select img {
  width: 60px;
  height: 60px;
  margin-right: 30px;
}

.contenido-select {
  display: flex;
  align-items: center;
}

.titulo {
  color: #006400;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.descripcion {
  font-size: 18px;
  color: #434343;
  margin-bottom: 0;
}

.opciones {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
  max-height: 400px;
  overflow: auto;
  z-index: 99999;
  width: 100%;
  display: none;
  position: absolute;
}

.opciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-100px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}
.contenido-opcion {
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s ease all;
}

.opciones .contenido-opcion {
  padding: 20px;
}

.contenido-opcion img {
  width: 60px;
  height: 60px;
  margin-right: 30px;
}

.opciones .contenido-opcion:hover {
  background: #153B34;
}

.opciones .contenido-opcion:hover .titulo,
.opciones .contenido-opcion:hover .descripcion {
  color: #fff;
}

.form-control:disabled {
  background-color: #fff;
  cursor: default;
}

@media screen and (max-width: 800px) {
  .selectbox {
    width: 100%;
  }
}
.tabela-wrapper {
  background-color: #006400;
  padding: 2rem 3rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575.98px) {
  .tabela-wrapper {
    padding: 2rem 1rem;
  }
}
.tabela-wrapper table {
  background-color: white;
}
.tabela-wrapper table thead {
  background-color: #006400;
}
.tabela-wrapper table thead tr th {
  font-size: 16px;
  color: #FFFFFF;
}
@media (max-width: 575.98px) {
  .tabela-wrapper table thead tr th {
    font-size: 12px;
  }
}
.tabela-wrapper table tbody tr .tr-padding {
  padding: 2rem 0;
}
.tabela-wrapper table tbody tr th .img-flag {
  width: 60px;
}
@media (max-width: 575.98px) {
  .tabela-wrapper table tbody tr th .img-flag {
    width: 30px;
  }
}
.tabela-wrapper table tbody tr td span {
  font-family: "Lato", sans-serif;
  font-size: 18px;
}
@media (max-width: 575.98px) {
  .tabela-wrapper table tbody tr td span {
    font-size: 9px;
  }
}
.tabela-wrapper table tbody tr td span.cotacao-text {
  font-weight: bold;
}

@media screen and (max-width: 576px) {
  .tabela-wrapper {
    overflow-x: scroll;
  }
  .tabela-wrapper .table {
    width: max-content;
  }
}
.cartao-wrapper .img-fluid {
  max-width: 85%;
  height: auto;
}

.newsletter-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.newsletter-wrapper p {
  max-width: 600px;
}
.newsletter-wrapper .newsletter-form .input-email, .newsletter-wrapper .newsletter-form .input-name {
  height: 47px;
  width: 100%;
  padding: 0 1rem;
  outline: none;
}
.newsletter-wrapper .newsletter-form .input-email:hover, .newsletter-wrapper .newsletter-form .input-name:hover {
  box-shadow: none;
  border: 0.2rem solid #b8dac278;
}

.lojas-wrapper .swiper {
  width: 100%;
  height: 100%;
}
.lojas-wrapper .swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 412px;
  height: 575px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.lojas-wrapper .swiper-button-prev, .lojas-wrapper .swiper-button-next {
  padding: 3rem 2.5rem;
  background-color: #006400;
}
.lojas-wrapper .swiper-button-prev::after, .lojas-wrapper .swiper-button-next::after {
  color: #FFFFFF;
  font-size: 25px;
}
.lojas-wrapper .swiper-button-prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.lojas-wrapper .swiper-button-next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

@media screen and (max-width: 992px) {
  .lojas-wrapper .swiper .swiper-slide {
    background-color: #f0f8ff00;
  }
  .lojas-wrapper .swiper-button-prev, .lojas-wrapper .swiper-button-next {
    display: none;
  }
}
.servicos-wrapper .swiper {
  width: 100%;
  height: 100%;
}
.servicos-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.servicos-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.servicos-wrapper .swiper-button-prev, .servicos-wrapper .swiper-button-next {
  padding: 3rem 2.5rem;
  background-color: #006400;
}
.servicos-wrapper .swiper-button-prev::after, .servicos-wrapper .swiper-button-next::after {
  color: #FFFFFF;
  font-size: 25px;
}
.servicos-wrapper .swiper-button-prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.servicos-wrapper .swiper-button-next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.home-hero .swiper {
  height: 142px;
}
.home-hero .swiper .swiper-slide {
  background-size: cover;
  background-repeat: no-repeat;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-hero .swiper .swiper-slide .swiper-img {
  object-fit: cover;
  width: 100%;
}
.home-hero .swiper .swiper-slide .swiper-title-wrapper .title {
  font-size: 4rem;
  font-family: "hk-bold-italic";
  color: #FFFFFF;
}
.home-hero .swiper .swiper-slide .swiper-title-wrapper .subtitle-wrapper {
  display: flex;
  flex-direction: column;
  color: #B8DAC2;
  font-size: 4rem;
  border-left: 2px solid #D3A130;
  padding: 0.3rem 1rem;
}
.home-hero .swiper .swiper-button-prev, .home-hero .swiper .swiper-button-next {
  padding: 5rem 2.5rem;
  background-color: #006400;
}
.home-hero .swiper .swiper-button-prev::after, .home-hero .swiper .swiper-button-next::after {
  color: #FFFFFF;
  font-size: 25px;
}
.home-hero .swiper .swiper-button-prev {
  left: 0;
  border-radius: 0 5px 5px 0;
}
.home-hero .swiper .swiper-button-next {
  right: 0;
  border-radius: 5px 0 0 5px;
}

.simulador-wrapper {
  background-color: #006400;
}

@media (min-width: 575.98px) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 200px;
  }
}
@media (min-width: 767.98px) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 368px;
  }
}
@media (min-width: 1199.98px) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 554px;
  }
}
@media (min-width: 1399.98px) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 550px;
  }
}
@media (min-width: 1599.98) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 700px;
  }
}
@media (min-width: 1920.98) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 745px;
  }
}
@media (min-width: 2100.98px) {
  .home-hero .swiper, .home-hero .swiper-img {
    height: 845px;
  }
}
.contato-wrapper .telefone {
  margin-top: 4rem;
}

.banner-hero .background-config {
  height: 70vh;
}
.banner-hero .title-wrapper {
  top: 30%;
  left: 10%;
}
.banner-hero .title-wrapper .title {
  text-align: start;
  font-size: 5rem;
  font-family: "hk-bold-italic";
  color: #FFFFFF;
}
.banner-hero .title-wrapper .subtitle-wrapper {
  display: flex;
  flex-direction: column;
  color: #B8DAC2;
  font-size: 4rem;
  border-left: 2px solid #D3A130;
  padding: 0.3rem 1rem;
}

.banner-hero .background-config {
  height: 70vh;
}
.banner-hero .title-wrapper {
  top: 30%;
  left: 10%;
}
.banner-hero .title-wrapper .title {
  text-align: start;
  font-size: 5rem;
  font-family: "hk-bold-italic";
  color: #FFFFFF;
}
.banner-hero .title-wrapper .subtitle-wrapper {
  display: flex;
  flex-direction: column;
  color: #B8DAC2;
  font-size: 4rem;
  border-left: 2px solid #D3A130;
  padding: 0.3rem 1rem;
}

.banner-hero .background-config {
  height: 70vh;
  overflow: hidden;
}
.banner-hero .title-wrapper {
  top: 30%;
  left: 10%;
}
.banner-hero .title-wrapper .title {
  text-align: start;
  font-size: 5rem;
  font-family: "hk-bold-italic";
  color: #FFFFFF;
}
.banner-hero .title-wrapper .subtitle-wrapper {
  display: flex;
  flex-direction: column;
  color: #B8DAC2;
  font-size: 4rem;
  border-left: 2px solid #D3A130;
  padding: 0.3rem 1rem;
  max-width: 430px;
}

@media screen and (max-width: 768px) {
  .banner-hero .background-config {
    height: 60vh;
    position: relative;
  }
  .banner-hero .background-config::after {
    content: "";
    width: 0;
    height: 120%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f6f6f6;
    box-shadow: 0 0 50px 300px #100e0e5e;
  }
  .banner-hero .title-wrapper {
    top: 15%;
    left: 2%;
  }
}