.newsletter-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    p {
        max-width: 600px;
    }

    .newsletter-form {
        .input-email, .input-name {
            height: 47px;
            width: 100%;
            padding: 0 1rem;
            outline: none;

            &:hover {
                box-shadow: none;
                border: 0.2rem solid #b8dac278;
                color: map-get($colors, black);
            }
        }
    }
}