// Use `@extend %button-defaults` to load the default styles below for the button
%button-defaults {
  display: inline-block;
  border-radius: 4px;
  padding: 12px 16px;
  border: none;
  transition: .2s ease-in-out;
  font-size: 18px;
}


// The `btn` class is the default button. You can add more button styles at
// the end or overwrite its style directly.

/**
 * Solid button
 */
.btn-theme {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: color(green-300);
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
  }

  span {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: color(green-500);
  }

  svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: color(green-500);
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
   }

   &:hover:before {
    width: 100%;
    background: color(green-300);
   }
   &:hover svg {
    transform: translateX(0);
   }
   &:active {
    transform: scale(0.95);
   }
}


/**
 * Flat button
 */
.btn-flat {
  background-color: transparent;
  color: #202020;
  box-shadow: none;
  @extend %button-defaults;

  &:hover {
    background-color: rgba(#000, 10%);
    color: #202020;
  }

  &:active {
    background-color: rgba(#000, 20%);
    color: #202020;
  }

  &:focus-visible {
      background-color: rgba(#000, 20%);
  }
}

.btn-receber-alerta {
  background-color: color(green-300);
  font-family: 'Bebas Neue', cursive;
  color: color(green-500);
  box-shadow: none;
  display: flex;
  font-size: 18px;

  @extend %button-defaults;

  @media (max-width: 575.98px){
    padding: 10px;

  }

  img {
    width: 20px;

    @media (max-width: 575.98px){
        width: 10px;
    }
  }

  &:hover {
    background-color: rgba(#000, 10%);
    color: #202020;
  }

  &:active {
    background-color: rgba(#000, 20%);
    color: #202020;
  }

  &:focus-visible {
      background-color: rgba(#000, 20%);
  }
}

.btn-comprar {
  background-color: color(red-500);
  font-family: 'Bebas Neue', cursive;
  color: color(white-500);
  box-shadow: none;
  font-size: 18px;

  @extend %button-defaults;

  &:hover {
    background-color: rgba(#000, 10%);
    color: #202020;
  }

  &:active {
    background-color: rgba(#000, 20%);
    color: #202020;
  }

  &:focus-visible {
      background-color: rgba(#000, 20%);
  }
}

.btn-newsletter {
  background-color: color(red-500);
  font-family: 'Bebas Neue', cursive;
  color: color(white-500);
  box-shadow: none;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 16px;
  border: none;
  transition: .2s ease-in-out;
  font-size: 18px;

  &:hover {
    background-color: #C99318;
    color: color(white-500);
  }

  &:active {
    background-color: #C99318;
    color: color(white-500);
  }

  &:focus-visible {
    background-color: #C99318;
  }
}

.btn-oblique {
  background-color: color(red-500);
  font-family: 'Bebas Neue', cursive;
  color: color(white-500);
  box-shadow: none;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 20px;
  border: none;
  transition: .2s ease-in-out;
  font-size: 20px;
  -webkit-transform: skewX(-14deg);
  -ms-transform: skewX(-14deg);
  transform: skewX(-14deg);

    margin-bottom: 15px;






  &:hover {
    background-color: #C99318;
    color: color(white-500);
  }

  &:active {
    background-color: #C99318;
    color: color(white-500);
  }

  &:focus-visible {
    background-color: #C99318;
  }


}








// Add more button styles below...
