.tabela-wrapper {
    background-color: color(green-500);
    padding: 2rem 3rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 575.98px){
    padding: 2rem 1rem;
    }

    table {
        background-color: white;

        thead {
            background-color: color(green-500);
            tr {
                th {
                    font-size: 16px;
                    color: color(white-500);

                    @media (max-width: 575.98px){
                        font-size: 12px;

                    }

                }
            }
        }

        tbody {
            tr {
                .tr-padding {
                    padding: 2rem 0;
                }

                th {
                    .img-flag {
                        width: 60px;

                        @media (max-width: 575.98px){
                            width: 30px;

                        }
                    }
                }
                td {
                    span {
                        font-family: 'Lato', sans-serif;
                        font-size: 18px;

                        @media (max-width: 575.98px){
                            font-size: 9px;
                        }
                    }
                    span.cotacao-text {
                        font-weight: bold;
                    }
                }
            }

        }
    }
}

// Small devices (landscape phones, less than 768px)
@include media(sm) {
    .tabela-wrapper {
        overflow-x: scroll;

        .table {
            width: max-content;
        }
    }
}
