.site-footer-informations {
  background-color: color(black-500);
  padding: 3rem 0;

  p {
    font-size: 16px;
    font-weight: 600;
  }
  .nav-footer {
    border-top: 2px solid white;
    .nav-item {
      .nav-link {
        color: color(white-500);
        font-weight: 600;

        &:hover {
          color: color(green-300);
        }
      }
    }
  }

  .col-center {
    border-right: 2px solid white;
    border-left: 2px solid white;
  }

  i {
    font-size: 40px;
    color: color(white-500);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: color(green-300);
    }
  }
}


.site-footer-developed{
  background-color: color(grey-600);

  .wrapper {
    display: flex;
    padding: 1rem 0;

    span, a {
      color: color(white-500);
      font-size: 1.4rem;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@include media(md) {
  .site-footer-informations {
    .nav-footer {
      border-top: none;
    }
    .col-center {
      border-right:none;
      border-left: none;
    }
  }
}
