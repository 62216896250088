.mensagem-alerta {
  z-index: 2; 
  top: 112px;
  right: 16px; 
  position: absolute; 
  max-width: 400px; 

  @media(max-width: 568px) {
    left: 8px;
    right: 8px;
  }
}