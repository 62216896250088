// Crie sua navbar lindona aqui. :)
.navbar {
    background-color: color(green-500);
    padding: 2.2rem 0.5rem;
    transition: all 0.3s ease-in-out;
    z-index: 1;

    .navbar-toggler {

        i.fas {
            color: color(green-300);
            font-size: 3rem;
        }
    }

    .navbar-collapse {
        .navbar-nav {
            .nav-item {
                display: flex;
                justify-content: flex-end;
                width: fit-content;

                &:not(:last-child) {
                   margin-right: 1.5rem;
                }

                & > div {
                    i {
                        font-size: 3rem;
                        color: color(green-300);
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            color: color(red-500);
                        }
                    }
                }

                .nav-link {
                    color: color(white-500);
                    font-size: 2rem;
                }

                &:hover:not(.active) {
                    .nav-link {
                        color: color(green-300);
                    }
                }

                &.active {
                    font-weight: 600;
                    border-bottom: 2px solid color(red-500);
                }
            }
        }
    }
}
